import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState: { data?: any } = {}

export const ViewDetailedSkuSlice = createSlice({
    name: "UpdateCounterAction",
    initialState: initialState,
    reducers: {
        loadViewDetailedSkuSlice: (state, actions: PayloadAction<any>) => {
            state.data = actions.payload ?? undefined
        },
    },
});

export const { loadViewDetailedSkuSlice } = ViewDetailedSkuSlice.actions
export default ViewDetailedSkuSlice.reducer
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState: { data?: any } = {}

export const ProductListSlice = createSlice({
    name: "UpdateCounterAction",
    initialState: initialState,
    reducers: {
        loadProductListSlice: (state, actions: PayloadAction<any>) => {
            state.data = actions.payload?.data ?? undefined
        },
    },
});

export const { loadProductListSlice } = ProductListSlice.actions
export default ProductListSlice.reducer
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState: { data?: any } = {}

export const ServiceAreaListSlice = createSlice({
    name: "UpdateCounterAction",
    initialState: initialState,
    reducers: {
        loadServiceAreaListSlice: (state, actions: PayloadAction<any>) => {
            state.data = actions?.payload?.data ?? undefined
        },
    },
});

export const { loadServiceAreaListSlice } = ServiceAreaListSlice.actions
export default ServiceAreaListSlice.reducer
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState: { data?: any } = {}

export const ViewSkuPreviewSlice = createSlice({
    name: "UpdateCounterAction",
    initialState: initialState,
    reducers: {
        loadViewSkuPreview: (state, actions: PayloadAction<any>) => {
            state.data = actions.payload ?? undefined
        },
    },
});

export const { loadViewSkuPreview } = ViewSkuPreviewSlice.actions
export default ViewSkuPreviewSlice.reducer
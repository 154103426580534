import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState: { data?: any } = {}

export const CategorySlice = createSlice({
    name: "UpdateCounterAction",
    initialState: initialState,
    reducers: {
        loadCategory: (state, actions: PayloadAction<any>) => {
            state.data = actions.payload ?? undefined
        },
    },
});

export const { loadCategory } = CategorySlice.actions
export default CategorySlice.reducer
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState: { data?: any } = {}

export const ViewAttributesSlice = createSlice({
    name: "UpdateCounterAction",
    initialState: initialState,
    reducers: {
        loadViewAttributes: (state, actions: PayloadAction<any>) => {
            state.data = actions.payload ?? undefined
        },
    },
});

export const { loadViewAttributes } = ViewAttributesSlice.actions
export default ViewAttributesSlice.reducer
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState: { data?: any } = {}

export const FilterServiceAreaSlice = createSlice({
    name: "UpdateCounterAction",
    initialState: initialState,
    reducers: {
        loadFilterServiceArea: (state, actions: PayloadAction<any>) => {
            state.data = actions.payload ?? undefined
        },
    },
});

export const { loadFilterServiceArea } = FilterServiceAreaSlice.actions
export default FilterServiceAreaSlice.reducer
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState: {
    district_id?: number, redefined_data?: {
        displayName: any; text: string, value: string
    }[]
} = {}

export const DistrictDealerListSlice = createSlice({
    name: "DistrictDealerListSlice",
    initialState: initialState,
    reducers: {
        loadDistrictDealerList: (state, action: PayloadAction<any>) => {
            state.district_id = action.payload?.data?.district_id as number ?? undefined;
            state.redefined_data = action.payload?.data?.map((dist_dealer: any) => ({
                text: dist_dealer?.gst_number?.toString(),
                value: dist_dealer?.dealer_id?.toString(),
                displayName: dist_dealer?.display_name?.toString(),
            }));
        },
    },
});


export const { loadDistrictDealerList } = DistrictDealerListSlice.actions
export default DistrictDealerListSlice.reducer
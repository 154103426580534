import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState: { data?: any } = {}

export const ImageUploadSlice = createSlice({
    name: "UpdateCounterAction",
    initialState: initialState,
    reducers: {
        loadImageUploadDetails: (state, actions: PayloadAction<any>) => {
            state.data = actions.payload.data ?? undefined
        },
    },
});

export const { loadImageUploadDetails } = ImageUploadSlice.actions
export default ImageUploadSlice.reducer
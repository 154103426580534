import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState: { state_id?: number, redefined_data?: { text: string, value: string }[] } = {}

export const DistrictListSlice = createSlice({
    name: "UpdateCounterAction",
    initialState: initialState,
    reducers: {
        loadDistrictList: (state, actions: PayloadAction<any>) => {
            state.state_id = actions?.payload?.data?.state_id as number ?? undefined
            state.redefined_data = actions?.payload?.data?.map((ele: any,) => {
                return {
                    text: ele.district_name.toString(),
                    value: ele.id.toString(),
                }
            })
        },
    },
});

export const { loadDistrictList } = DistrictListSlice.actions
export default DistrictListSlice.reducer
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState: { data?: any } = {}

export const GSTListSlice = createSlice({
    name: "UpdateCounterAction",
    initialState: initialState,
    reducers: {
        loadGSTList: (state, actions: PayloadAction<any>) => {
            state.data = actions.payload.data ?? undefined
        },
    },
});

export const { loadGSTList } = GSTListSlice.actions
export default GSTListSlice.reducer
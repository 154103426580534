import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState: { data?: any } = {}

export const BrandListSlice = createSlice({
    name: "UpdateCounterAction",
    initialState: initialState,
    reducers: {
        loadBrandList: (state, actions: PayloadAction<any>) => {
            state.data = actions.payload ?? undefined
        },
    },
});

export const { loadBrandList } = BrandListSlice.actions
export default BrandListSlice.reducer
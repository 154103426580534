import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState: { data?: any, } = {}

export const SegmentDetailsSlice = createSlice({
    name: "SegmentDetails",
    initialState: initialState,
    reducers: {
        segmentCategoryList: (state, action: PayloadAction<any[]>) => {
            state.data = action.payload;
        }
    },
});

export const { segmentCategoryList } = SegmentDetailsSlice.actions
export default SegmentDetailsSlice.reducer
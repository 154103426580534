import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState: {
    subCategory?: any
} = {}

export const SegmentSubCategoryListSlice = createSlice({
    name: "SegmentSubCategoryList",
    initialState: initialState,
    reducers: {
        loadSubCategory: (state, actions: PayloadAction<any>) => {
            state.subCategory = actions.payload
        },
    },
});

export const { loadSubCategory } = SegmentSubCategoryListSlice.actions
export default SegmentSubCategoryListSlice.reducer
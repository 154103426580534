import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState: { data?: any, status?: any, message?: any } = {}

export const SendTextImageSlice = createSlice({
    name: "UpdateCounterAction",
    initialState: initialState,
    reducers: {
        loadSendTextImage: (state, actions: PayloadAction<any>) => {
            state.data = actions.payload.data ?? undefined
            state.status = actions.payload.status ?? undefined
            state.message = actions.payload.message ?? undefined
        },
    },
});

export const { loadSendTextImage } = SendTextImageSlice.actions
export default SendTextImageSlice.reducer
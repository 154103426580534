import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState: { status?: number, message?: string, data?: any, pin_code?: number, redefined_data?: any } = {}

export const DealerListSlice = createSlice({
    name: "UpdateCounterAction",
    initialState: initialState,
    reducers: {
        loadDealerList: (state, actions: PayloadAction<any>) => {
            state.pin_code = actions?.payload?.data?.pin_code as number ?? undefined
            state.status = actions?.payload?.status as number ?? undefined
            state.message = actions?.payload?.message as string ?? undefined
            state.data = actions?.payload?.data ?? undefined
            state.redefined_data = actions?.payload?.data
                ?.map((gst: any) => ({
                    text: gst?.gst_number?.toString(),
                    value: gst?.id?.toString(),
                    display_name: gst?.display_name
                }));
        },
    },
});

export const { loadDealerList } = DealerListSlice.actions
export default DealerListSlice.reducer
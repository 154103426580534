import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState: { data?: any } = {}

export const ViewProductPreviewSlice = createSlice({
    name: "UpdateCounterAction",
    initialState: initialState,
    reducers: {
        loadViewProductPreview: (state, actions: PayloadAction<any>) => {
            state.data = actions.payload ?? undefined
        },
    },
});

export const { loadViewProductPreview } = ViewProductPreviewSlice.actions
export default ViewProductPreviewSlice.reducer
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const initialState: { data?: any, status?: any, message?: any } = {}

export const SendAlternateDetailsSlice = createSlice({
    name: "UpdateCounterAction",
    initialState: initialState,
    reducers: {
        loadSendAlternateDetails: (state, actions: PayloadAction<any>) => {
            state.data = actions.payload.data ?? undefined
            state.status = actions.payload.status ?? undefined
            state.message = actions.payload.message ?? undefined
        },
    },
});

export const { loadSendAlternateDetails } = SendAlternateDetailsSlice.actions
export default SendAlternateDetailsSlice.reducer